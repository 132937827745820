import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Image from "~components/image/image"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"

/* Import Local Components */
import PostHeader from "./components/postHeader/postHeader"

/* Import Local Style(s) */
import "./post.scss"

const Post = ({ location, data: { sanityNewsPost: newsPost } }) => {
  const { image, _rawBody } = newsPost

  return (
    <Page title="Post" location={location} className="template">
      <Helmet>
        <style>{`body { background-color: var(--color-lightest-gray); }`}</style>
      </Helmet>
      <PostHeader post={newsPost} location={location} />
      <PageArticle title="Post" className="post">
        <PageArticleSection className="post">
          {image && <Image image={image.asset.gatsbyImageData} />}
          <div className="news_text">
            <BlockContent blocks={_rawBody} />  
          </div>
        </PageArticleSection>
      </PageArticle>
    </Page>
  )
}

export default Post

export const query = graphql`
  query ($id: String!) {
    sanityNewsPost(id: { eq: $id }) {
      ...fieldsOnNewsPostDocument
    }
  }
`
